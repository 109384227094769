import { createApp } from "vue";
import App from "./App.vue";

import '@fortawesome/fontawesome-free/js/all.js';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import router from "./router";

createApp(App).use(router).mount("#app");
