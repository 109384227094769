<template>
  <div class="page">
    <div class="content">
      <h1>Weinregal</h1>
      <table>
        <!-- <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">First</th>
          <th scope="col">Last</th>
          <th scope="col">Handle</th>
        </tr>
      </thead> -->
        <tbody>
          <tr v-for="row in rows_" :key="row">
            <td
              v-for="col in row"
              :key="col"
              :class="{ active: col.isActive, disabled: col.isDisabled }"
              draggable="true"
              @dragstart="startDrag($event, col.row, col.col)"
              @drop="onDrop($event, col.row, col.col)"
              @dragover.prevent
              @dragenter.prevent
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="col.name + '\n' + col.price + '\n' + col.articleNumber"
            >
              <!-- <div>{{ `[${i}, ${j}]` }}</div> -->
              <!-- <img :src="getImage(j, i)" /> -->
              <img
                v-if="col.image"
                :src="`data:image/png;base64,${col.image}`"
              />
              <!-- <div class="edit-buttons">
                <router-link
                  v-if="!isDisabled(j, i)"
                  :to="{ name: 'BottlesChoose', params: { column: j, row: i } }"
                  class="btn btn-outline-secondary btn-sm"
                >
                  <i class="fa-solid fa-folder-open"></i>
                </router-link>
                <div
                  v-if="!isDisabled(j, i) && hasCompartment(j, i)"
                  class="btn btn-outline-secondary btn-sm"
                  @click="remove(j, i)"
                >
                  <i class="fa-solid fa-xmark"></i>
                </div>
              </div> -->
              <div class="wine-type" :class="col.compartment?.type"></div>
              <div class="errors" v-if="col.compartment?.wineDuplicates > 1">
                Duplicates: {{ col.compartment?.wineDuplicates }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="legend">
        <div>Weißwein</div>
        <div class="wine-type white"></div>

        <div>Rotwein</div>
        <div class="wine-type red"></div>

        <div>Rosé</div>
        <div class="wine-type rose"></div>

        <div>Schaumwein</div>
        <div class="wine-type sparkling"></div>

        <div>Spirituosen</div>
        <div class="wine-type spirits"></div>
      </div>
    </div>

    <div
      class="
        sidebar
        d-flex
        flex-column
        align-items-stretch
        flex-shrink-0
        bg-white
      "
      @drop="onDropRemoveWine($event)"
      @dragover.prevent
      @dragenter.prevent
    >
      <div class="m-3">Weine (nicht im Regal)</div>
      <div class="unused">
        <div
          class="unused-item m-1"
          v-for="wine in unused"
          :key="wine.id"
          @dragstart="startDragUnsetWine($event, wine)"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="wine.name + '\n' + wine.price + '\n' + wine.articleNumber"
        >
          <!-- <div>{{ wine.id }}</div>
          <div>{{ wine.name }}</div> -->
          <img v-if="wine.image" :src="`data:image/png;base64,${wine.image}`" />
          <div class="wine-type" :class="wine.type"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "vue";

export default {
  setup() {
    return {
      cols: 15,
      rows: 7,
      image: ref(null),
      compartments: ref([]),
      unused: ref([]),
      rows_: ref([]),
    };
  },
  methods: {
    getImage(col, row) {
      if (!this.compartments) return;
      const compartment = this.compartments.find(
        (b) => b.column == col && b.row == row
      );
      if (compartment) {
        return compartment.image;
      }
    },
    getCompartment(col, row) {
      if (!this.compartments) return;
      const compartment = this.compartments.find(
        (b) => b.column == col && b.row == row
      );
      return compartment;
    },
    hasCompartment(col, row) {
      if (!this.compartments) return;
      const compartment = this.compartments.find(
        (b) => b.column == col && b.row == row
      );
      if (compartment) {
        return true;
      }
      return false;
    },
    isActive(col, row) {
      if (col == 7 && row == 4) {
        return false;
      }
      return false;
    },
    isDisabled(col, row) {
      const disabledCompartments = [
        [1, 5],
        [1, 6],
        [1, 7],
        [3, 5],
        [3, 6],
        [4, 5],
        [4, 6],
        [5, 5],
        [5, 6],
        [6, 5],
        [6, 6],

        [10, 5],
        [10, 6],
        [11, 5],
        [11, 6],
        [12, 5],
        [12, 6],
        [13, 5],
        [13, 6],
      ];

      for (let c of disabledCompartments) {
        if (c[0] == col && c[1] == row) return true;
      }
    },
    async remove(col, row) {
      const body = {
        column: col,
        row: row,
      };
      await axios.delete("/api/compartments", { data: body });

      this.load();
    },
    async move(fromPos, toPos) {
      const body = {
        source: fromPos,
        destination: toPos,
      };
      await axios.post("/api/compartments/move", body);

      this.load();
    },
    async setWine(wineId, col, row) {
      await axios.post("/api/compartments", {
        wineId,
        column: col,
        row: row,
      });

      this.load();
    },

    startDrag(event, row, col) {
      console.log("start drag", row, col);
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("type", "move-wine");
      event.dataTransfer.setData("source_i", row);
      event.dataTransfer.setData("source_j", col);
    },
    startDragUnsetWine($event, wine) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("type", "set-wine");
      event.dataTransfer.setData("wineId", wine.id);
    },
    async onDrop(event, row, col) {
      console.log("on drop", row, col, event);

      const type = event.dataTransfer.getData("type");
      if (type == "move-wine") {
        const source_i = parseInt(event.dataTransfer.getData("source_i"));
        const source_j = parseInt(event.dataTransfer.getData("source_j"));
        console.log("source", source_i, source_j);
        await this.move(
          { row: source_i, col: source_j },
          { row: row, col: col }
        );
      }
      if (type == "set-wine") {
        const wineId = event.dataTransfer.getData("wineId");
        await this.setWine(wineId, col, row);
      }
    },
    async onDropRemoveWine(event) {
      const type = event.dataTransfer.getData("type");
      console.log("on drop remove");
      if (type == "move-wine") {
        const row = parseInt(event.dataTransfer.getData("source_i"));
        const col = parseInt(event.dataTransfer.getData("source_j"));
        console.log("source", row, col);
        await this.remove(col, row);
      }
    },
    validateCompartments(compartments) {
      for (var compartment of compartments) {
        var countWines = compartments.filter(
          (c) => c.wineId == compartment.wineId
        ).length;
        compartment.wineDuplicates = countWines;
        // console.log("compartment:", compartment.wineId, countWines);
      }
      return compartments;
    },
    async load() {
      const response = await axios.get("/api/compartments");
      this.compartments = this.validateCompartments(response.data);

      const responseUnused = await axios.get("/api/bottles-unset");
      this.unused = responseUnused.data;

      const rows = [];
      const rowCount = 7;
      const colCount = 15;
      var id = 0;
      for (var row = 1; row <= rowCount; row++) {
        const cols = [];
        for (var col = 1; col <= colCount; col++) {
          var compartment = this.getCompartment(col, row);
          var cell = {
            id: ++id,
            row,
            col,
            wineId: compartment?.wineId,
            name: compartment?.name,
            articleNumber: compartment?.articleNumber,
            description: compartment?.description,
            price: compartment?.price,
            image: compartment?.image,
            imageId: compartment?.imageId,
            compartment,
            isActive: this.isActive(col, row),
            isDisabled: this.isDisabled(col, row),
          };
          cols.push(cell);
        }
        rows.push(cols);
      }
      this.rows_ = rows;
    },
  },
  async mounted() {
    this.load();
  },
};
</script>

<style scoped>
table {
  width: 100%;
  table-layout: fixed;
  border: 1px solid gray;
}
td {
  border: 1px solid gray;
  height: 100px;
  position: relative;
}
img {
  width: 100%;
  /* width: 100%;
  height: 100%; */
}
td.active {
  border: 2px solid blue;
}

td.disabled {
  background: gray;
}

.edit-buttons {
  display: flex;
  justify-content: center;
  padding: 5px;
}
.edit-buttons .btn {
  margin: 5px;
}

.unused-item {
  position: relative;
}

.legend {
  position: relative;
  width: 150px;
}
.legend .wine-type {
  position: relative;
}

.wine-type {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.wine-type.red {
  background-color: rgb(96, 2, 2);
}
.wine-type.white {
  background-color: rgb(252, 224, 184);
}
.wine-type.rose {
  background-color: rgb(255, 188, 171);
}
.wine-type.sparkling {
  background-color: rgb(179, 252, 255);
}
.wine-type.spirits {
  background-color: darkgray;
}

.errors {
  font-size: 14px;
  color: red;
  font-weight: 500;
}

.unused {
  display: flex;
  flex-wrap: wrap;
}

.unused img {
  height: 100px;
}

.page {
  display: flex;
}
.page .content {
  padding: 40px;
}
.page .sidebar {
  width: 380px;
}
</style>
