import { createRouter, createWebHashHistory } from "vue-router";
import Shop from "../views/Shop.vue";

const routes = [
  {
    path: "/",
    name: "Shop",
    component: Shop,
  },
  {
    path: "/bottles",
    name: "Bottles",
    component: () =>
      import(/* webpackChunkName: "bottles" */ "../views/Bottles.vue"),
  },
  {
    path: "/bottles/new",
    name: "BottleNew",
    component: () =>
      import(/* webpackChunkName: "bottles" */ "../views/BottleNew.vue"),
  },
  {
    path: "/bottles/choose/:column/:row",
    name: "BottlesChoose",
    props: true,
    component: () =>
      import(/* webpackChunkName: "bottles" */ "../views/BottlesChoose.vue"),
  },
  {
    path: "/bottles/edit/:bottleId",
    name: "BottleEdit",
    props: true,
    component: () =>
      import(/* webpackChunkName: "bottles" */ "../views/BottleEdit.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
